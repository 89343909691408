import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import landing from '../../assets/images/landing-image-removebg.png';
import './Landing.css';


function Landing() {
    const { authToken } = useContext(AuthContext);

    return (
        <div className='landing'>
            <div className='image-container'>
                <img src={landing} alt='' />
            </div>
            <div className='content'>
                <h1>أهلًا بك في إقتناء</h1>
                <p>
                    مرحبًا بك في موقع إقتناء، الوجهة الرائدة لبيع وشراء الأعمال
                    الفنية. نحن نربط بين عشاق الفن والفنانين الموهوبين، حيث يمكنك
                    الاستمتاع بتجربة فريدة من نوعها في جمع واقتناء الفنون. تصفح
                    مجموعتنا المتنوعة من الأعمال الفنية الأصلية، واحصل على فرصة
                    لعرض وبيع إبداعاتك. انضم إلى مجتمعنا المتنوع وتواصل مع محبي
                    الفن والمجمعين العالميين في مكان واحد، حيث يلتقي الفن والشغف
                    بتجربة فريدة ومثيرة.
                </p>
                <div className="btns">
                    {!authToken && (
                        <Link to="/register">إنضم كفنان / كمقتني</Link>
                    )}
                    <Link to="/shopping">!تسوق الآن</Link>
                </div>
            </div>
        </div>
    )
}

export default Landing;