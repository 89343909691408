import shield_tick from '../../assets/images/shield-tick.svg';
import './Advantages.css';

function Advantages() {
    return (
        <div className='advantages'>
            <div className='container'>
                <hr />
                <div className='question'>
                    <h1>لماذا اقتناء؟</h1>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <img src={shield_tick} alt="" />
                        <div className="info">
                            <h2>أصليّة</h2>
                            <p>
                                نحن نقدم قطعًا أصلية تم التحقق منها من قبل فريقنا المتخصص.
                            </p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={shield_tick} alt="" />
                        <div className="info">
                            <h2>مميزة</h2>
                            <p>
                                نحن نقدم قطعًا أصلية تم التحقق منها من قبل فريقنا المتخصص.
                            </p>
                        </div>
                    </div>
                    <div className='card'>
                        <img src={shield_tick} alt="" />
                        <div className="info">
                            <h2>فريدة</h2>
                            <p>
                                نحن نقدم قطعًا أصلية تم التحقق منها من قبل فريقنا المتخصص.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages;