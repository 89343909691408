import React, { useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./MyArts.css";
import axios from "axios";

const initialValues = {
    title: '',
    description: '',
    price: '',
    artist: '',
    height: '',
    width: '',
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    pdf_file: null,
}

const validationSchema = Yup.object({
    title: Yup.string().required('هذا الحقل مطلوب'),
    description: Yup.string().required('هذا الحقل مطلوب'),
    price: Yup.string().required('هذا الحقل مطلوب'),
    image1: Yup.string().required('هذا الحقل مطلوب'),
    image2: Yup.string().required('هذا الحقل مطلوب'),
    image3: Yup.string().required('هذا الحقل مطلوب'),
    image4: Yup.string().required('هذا الحقل مطلوب'),
    image5: Yup.string().required('هذا الحقل مطلوب'),
    artist: Yup.string().required('هذا الحقل مطلوب'),
    height: Yup.string().required('هذا الحقل مطلوب'),
    width: Yup.string().required('هذا الحقل مطلوب'),
    pdf_file: Yup.mixed()
        .nullable() // Make sure the pdf_file is nullable
        .test(
            "fileSize",
            "يجب أن يكون حجم الملف أقل من 5 ميجا بايت",
            function (value) {
                if (!value) {
                    return true; // Optional field, no file provided
                }
                return value.size <= 5000000;
            }
        ),
})

const notify = () => {
    toast.success("تم إرسال الطلب بنجاح سيتم مراجعة الطلب و الرد عليكم قريبأ ", {
        position: "top-center",
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

function MyArts() {
    const [showForm, setShowForm] = useState(false);
    const [imagePreview, setImagePreview] = useState({});
    const { authToken } = useContext(AuthContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    // Refs for the file inputs
    const image1Ref = useRef(null);
    const image2Ref = useRef(null);
    const image3Ref = useRef(null);
    const image4Ref = useRef(null);
    const image5Ref = useRef(null);
    const pdfFileRef = useRef(null);

    const handleFileChange = (e, setFieldValue) => {
        const { name, files } = e.target;
        const file = files[0];
        setFieldValue(name, file);

        // setImagePreview(prev => ({
        //     ...prev,
        //     [name]: file ? URL.createObjectURL(file) : null
        // }));

        if (file) {
            if (file.type === 'image/heic') {
                console.log('file.type', file.type)
                // Handle HEIC image preview conversion
                const reader = new FileReader();
                reader.onload = () => {
                    setImagePreview(prev => ({
                        ...prev,
                        [name]: reader.result
                    }));
                };
                reader.readAsDataURL(file);
            } else {
                setImagePreview(prev => ({
                    ...prev,
                    [name]: URL.createObjectURL(file)
                }));
            }
        } else {
            setImagePreview(prev => ({
                ...prev,
                [name]: null
            }));
        }
    };

    const onSubmit = async (values, { setErrors, resetForm }) => {
        const formData = new FormData();
        for (let key in values) {
            if (values[key] !== null) {
                formData.append(key, values[key]);
            }
        }

        try {
            const response = await axios.post('api/paints', formData, {
                headers: {
                    Authorization: `Token ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Paints sent successfully', response);
            notify()
            resetForm();

            // Clear the file inputs
            if (image1Ref.current) image1Ref.current.value = '';
            if (image2Ref.current) image2Ref.current.value = '';
            if (image3Ref.current) image3Ref.current.value = '';
            if (image4Ref.current) image4Ref.current.value = '';
            if (image5Ref.current) image5Ref.current.value = '';
            if (pdfFileRef.current) pdfFileRef.current.value = '';

            // Clear image previews
            setImagePreview({});
        } catch (error) {
            setErrors(error.response.data);
            console.error("Error assigning default profile image:", error);
        }
    };

    const ImageModal = ({ image, onClose }) => (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <img src={image} alt="Preview" />
            </div>
        </div>
    );


    return (
        <div className="my-arts">
            {!showForm && (
                <>
                    <h3>لا يوجد أعمال فنية</h3>
                    <button
                        onClick={() => setShowForm(true)}
                        className="change-btn"
                    >
                        رفع لوحة
                    </button>
                </>
            )}

            {showForm && (

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                    encType="multipart/form-data"
                // validateOnChange={false}
                // validateOnBlur={false}
                >
                    {({ setFieldValue }) => (
                        <Form noValidate>
                            <ul className="upload-instructions">
                                <li>قم برفع الصورة الرئيسية و التي ستظهر في المعرض لاحقا في الحقل الأول</li>
                                <li>تأكد من رفع صور واضحة و ذات إضاءة جيدة</li>
                                <li>تأكد من رفع صور واضحة وبزاويا مختلفة للوحة</li>
                            </ul>
                            <div className='form-field'>
                                <label htmlFor="title">أسم اللوحة<span> *</span></label>
                                <Field type="text" id="title" name="title" className='text-input' />
                                <ErrorMessage name="title">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                            </div>

                            <div className='form-field'>
                                <label htmlFor="price">سعر اللوحة<span> *</span></label>
                                <Field type="number" id="price" name="price" className='text-input' />
                                <ErrorMessage name="price">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                            </div>

                            <div className='form-field'>
                                <label htmlFor="artist">أسم الفنان<span> *</span></label>
                                <Field type="text" id="artist" name="artist" className='text-input' />
                                <ErrorMessage name="artist">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                            </div>

                            <div className='form-field paint-size'>
                                <div className="height">
                                    <label htmlFor="height">الطول<span> *</span></label>
                                    <Field type="number" id="height" name="height" />
                                    <ErrorMessage name="height">
                                        {msg => <div className="errorMessage">{msg}</div>}
                                    </ErrorMessage>
                                </div>

                                <div className="width">
                                    <label htmlFor="width" id="width-label">العرض<span> *</span></label>
                                    <Field type="number" id="width" name="width" />
                                    <ErrorMessage name="width">
                                        {msg => <div className="errorMessage">{msg}</div>}
                                    </ErrorMessage>
                                </div>
                            </div>

                            <div className='form-field'>
                                <label htmlFor="description">وصف اللوحة<span> *</span></label>
                                <Field
                                    as="textarea"
                                    rows="4"
                                    cols="50"
                                    id="description"
                                    name="description"
                                    className="textarea" />
                                <ErrorMessage name="description">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                            </div>

                            <div className='form-field'>
                                <label htmlFor="image1">تحميل الصورة (jpg / png / jpeg)<span> *</span></label>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="image1"
                                        name="image1"
                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                        accept="image/png, image/jpeg, image/heic"
                                        ref={image1Ref}
                                    />
                                    {imagePreview['image1'] && (
                                        <Link onClick={() => {
                                            setCurrentImage(imagePreview['image1']);
                                            setModalVisible(true)
                                        }}>
                                            معاينة
                                        </Link>
                                    )}
                                </div>
                                <ErrorMessage name="image1">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                                {modalVisible && <ImageModal image={currentImage} onClose={() => setModalVisible(false)} />}
                            </div>

                            <div className='form-field'>
                                <label htmlFor="image2">تحميل الصورة (jpg / png / jpeg)<span> *</span></label>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="image2"
                                        name="image2"
                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                        accept="image/png, image/jpeg, image/heic"
                                        ref={image2Ref}
                                    />
                                    {imagePreview['image2'] && (
                                        <Link onClick={() => { setCurrentImage(imagePreview['image2']); setModalVisible(true); }}>
                                            معاينة
                                        </Link>
                                    )}
                                </div>
                                <ErrorMessage name="image2">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                                {modalVisible && <ImageModal image={currentImage} onClose={() => setModalVisible(false)} />}
                            </div>

                            <div className='form-field'>
                                <label htmlFor="image3">تحميل الصورة (jpg / png / jpeg)<span> *</span></label>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="image3"
                                        name="image3"
                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                        accept="image/png, image/jpeg, image/heic"
                                        ref={image3Ref}
                                    />
                                    {imagePreview['image3'] && (
                                        <Link onClick={() => { setCurrentImage(imagePreview['image3']); setModalVisible(true); }}>
                                            معاينة
                                        </Link>
                                    )}
                                </div>
                                <ErrorMessage name="image3">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                                {modalVisible && <ImageModal image={currentImage} onClose={() => setModalVisible(false)} />}
                            </div>

                            <div className='form-field'>
                                <label htmlFor="image4">تحميل الصورة (jpg / png / jpeg)<span> *</span></label>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="image4"
                                        name="image4"
                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                        accept="image/png, image/jpeg, image/heic"
                                        ref={image4Ref}
                                    />
                                    {imagePreview['image4'] && (
                                        <Link onClick={() => { setCurrentImage(imagePreview['image4']); setModalVisible(true); }}>
                                            معاينة
                                        </Link>
                                    )}
                                </div>
                                <ErrorMessage name="image4">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                                {modalVisible && <ImageModal image={currentImage} onClose={() => setModalVisible(false)} />}
                            </div>

                            <div className='form-field'>
                                <label htmlFor="image5">تحميل الصورة (jpg / png / jpeg)<span> *</span></label>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="image5"
                                        name="image5"
                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                        accept="image/png, image/jpeg, image/heic"
                                        ref={image5Ref}
                                    />
                                    {imagePreview['image5'] && (
                                        <Link onClick={() => { setCurrentImage(imagePreview['image5']); setModalVisible(true); }}>
                                            معاينة
                                        </Link>
                                    )}
                                </div>
                                <ErrorMessage name="image5">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                                {modalVisible && <ImageModal image={currentImage} onClose={() => setModalVisible(false)} />}
                            </div>

                            <div className='form-field'>
                                <label htmlFor="pdf_file"> شهادة الاصالة (PDF)</label>
                                <div className="file-input">
                                    <input
                                        type="file"
                                        id="pdf_file"
                                        name="pdf_file"
                                        onChange={(event) => {
                                            setFieldValue("pdf_file", event.currentTarget.files[0]);
                                        }}
                                        accept="application/pdf"
                                        ref={pdfFileRef}
                                    />
                                </div>
                                <ErrorMessage name="pdf_file">
                                    {msg => <div className="errorMessage">{msg}</div>}
                                </ErrorMessage>
                            </div>

                            <div className="btns-container">
                                <input type="submit" value="أرسل" />
                                <button
                                    type="button"
                                    className="cancle-btn"
                                    onClick={() => setShowForm(false)}
                                >
                                    إلغاء
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            <ToastContainer rtl />
        </div>
    )
}

export default MyArts;