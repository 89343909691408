import "./MyArtCollection.css";

function MyArtCollection() {

    return (
        <div className="my-art-collection">
            <h3>لا يوجد اي مقتنيات فنية</h3>
        </div>
    )
}

export default MyArtCollection;