// import Order from "../Order/Order";
// import React, { useState } from "react";
// import painting from '../../assets/images/Painting-details.png';
// import arrow_left from '../../assets/images/arrow-square-left.svg';
// import arrow_right from '../../assets/images/arrow-square-right.svg';
import "./MyOrders.css";


function MyOrders() {
    // const [paints] = useState(
    //     {
    //         tracking_number: "f1234",
    //         order_date: "12/05/24",
    //         order_details: {
    //             paint_name: 'Whole',
    //             paint_price: ' 620 ر.س',
    //             paint_image: painting
    //         },
    //         location: 'المملكة العربية السعودية، الخبر .',
    //         status: 'قيد المعالجة'
    //     });

    return (
        <div className='my-orders'>
            {/* <div className="container">
                <Order paints={paints} />
                <Order paints={paints} />
                <Order paints={paints} />
                <Order paints={paints} />
                <Order paints={paints} />
                <Order paints={paints} />
                <Order paints={paints} />
            </div>
            <div className="navgation">
                <img src={arrow_right} alt="" />
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <img src={arrow_left} alt="" />
            </div> */}
            <h3 id='no-orders'>لا يوجد طلبات</h3>
        </div>
    )
}

export default MyOrders;