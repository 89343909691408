import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { Formik, Form } from "formik";
import axios from "axios";


// Function to generate default image URL or base64 string
const generateDefaultImage = (name) => {
    const firstInitial = name ? name[0].toUpperCase() : "";
    const canvas = document.createElement('canvas');
    canvas.width = 150;
    canvas.height = 150;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#9f2f33';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#f5f5f5';
    ctx.font = 'bold 75px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(firstInitial, canvas.width / 2, canvas.height / 2);
    return canvas.toDataURL('image/png');
};

const base64ToFile = (base64, filename) => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};


function ProfileImage({showText}) {
    const [userImage, setUserImage] = useState({});
    const { authToken, userId } = useContext(AuthContext);

    const initialValues = {
        image: userImage || "",
    };


    useEffect(() => {
        const handleDefaultImageUpload = async (username) => {
            const defaultImage = generateDefaultImage(username);
            const file = base64ToFile(defaultImage, 'default.png');
            const formData = new FormData();
            formData.append("image", file);

            try {
                const response = await axios.patch(
                    `api/users-profiles/${userId}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Token ${authToken}`,
                        },
                    },
                );
                setUserImage(response.data.image);
                console.log("Default profile image assigned successfully:", response.data);
            } catch (error) {
                console.error("Error assigning default profile image:", error);
            }
        };

        if (userId) {
            const fetchUserProfile = async () => {
                try {
                    const response = await axios.get(
                        `api/users-profiles/${userId}`,
                        {
                            headers: {
                                Authorization: `Token ${authToken}`,
                            },
                        },
                    );
                    setUserImage(response.data.image);
                    if (!response.data.image) {
                        // If no image is found, assign default image to backend
                        handleDefaultImageUpload(response.data.username);
                    }
                    console.log("User image fetched:", response.data);
                } catch (error) {
                    console.error("Error fetching user image:", error);
                }
            };
            fetchUserProfile();
        }
    }, [userId, authToken]);

    const handleChange = async (e, setFieldValue) => {
        const file = e.currentTarget.files[0];
        setFieldValue("image", file);

        if (file) {
            const formData = new FormData();
            formData.append("image", file);

            try {
                const response = await axios.patch(
                    `api/users-profiles/${userId}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Token ${authToken}`,
                        },
                    },
                );
                setUserImage(response.data.image);
                console.log("Profile image updated successfully:", response.data);
            } catch (error) {
                console.error("Error updating profile image:", error);
            }
        }
    };

    return (
        <div className="profile-image section">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                encType="multipart/form-data"
            >
                {({ setFieldValue }) => (
                    <Form noValidate>
                        <div className="upload-image inp-container">
                            <label htmlFor="image">
                                <input
                                    type="file"
                                    id="image"
                                    name="image"
                                    accept="image/*"
                                    onChange={(e) => handleChange(e, setFieldValue)}
                                    style={{ display: "none" }}
                                />
                                <img
                                    src={userImage}
                                    alt="Profile"
                                    className="avatar"
                                />
                                {showText && <p>تعديل</p>}
                            </label>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default ProfileImage;
