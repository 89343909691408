import React, { useContext } from "react";
import { FavouritePaintContext } from "../contexts/FavouritePaintContext";
import PaintCard from "../PaintCard/PaintCard";
import arrow_left from '../../assets/images/arrow-square-left.svg';
import arrow_right from '../../assets/images/arrow-square-right.svg';
import "./MyFavourite.css";


function MyFavourite() {
    const { favouritePaints } = useContext(FavouritePaintContext);

    return (
        <div className='my-favourite'>
            {favouritePaints.length > 0 ? (
                <>
                    <div className='paint-cards'>
                        {favouritePaints.map((paint) => (
                            <PaintCard key={paint.id} paint={paint} />
                        ))}
                    </div>
                    <div className="navgation">
                        <img src={arrow_right} alt="" />
                        <span>1</span>
                        <span>2</span>
                        <span>3</span>
                        <img src={arrow_left} alt="" />
                    </div>
                </>
            ) : (
                <h3 id='no-fav-paints'>لا يوجد مفضلة</h3>
            )}
        </div>
    )
}

export default MyFavourite;