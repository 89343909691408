import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

export const FavouritePaintContext = createContext();

export const FavouritePaintProvider = ({ children }) => {
    const [favouritePaints, setFavouritePaints] = useState([]);
    const { authToken, isLoggedIn } = useContext(AuthContext);

    const addFavouritePaint = async (paintId) => {
        try {
            const response = await axios.post('/api/favourite-paints', { paint_id: paintId }, {
                headers: { Authorization: `Token ${authToken}` },
            });
            setFavouritePaints(response.data.paints)
            console.log('adding favorite paint:');
        } catch (error) {
            console.error('Error adding favorite paint:', error);
        }
    };

    const removeFavouritePaint = async (paintId) => {
        try {
            const response = await axios.delete('/api/favourite-paints/delete', {
                headers: { Authorization: `Token ${authToken}` },
                data: { paint_id: paintId },
            });
            setFavouritePaints(response.data.paints)
            console.log('removing favorite paint:');
        } catch (error) {
            console.error('Error removing favorite paint:', error);
        }
    };

    useEffect(() => {
        const fetchFavouritePaints = async () => {
            if (isLoggedIn && authToken) {
                try {
                    const response = await axios.get('/api/favourite-paints', {
                        headers: {
                            Authorization: `Token ${authToken}`,
                        },
                    });
                    setFavouritePaints(response.data[0].paints);
                } catch (error) {
                    console.error('Error fetching favorite paints:', error);
                }
            }
        };
        fetchFavouritePaints();
    }, [isLoggedIn, authToken]);

    return (
        <FavouritePaintContext.Provider value={{ favouritePaints, addFavouritePaint, removeFavouritePaint }}>
            {children}
        </FavouritePaintContext.Provider>
    );
};
