import React, { useState, useContext, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "./Profile.css";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";
import ProfileImage from "../ProfileImage";
import useRequireAuth from '../hooks/useRequireAuth';


function Profile() {
    const [profile, setProfile] = useState({});
    const { isLoggedIn, userId, authToken } = useContext(AuthContext);

    useRequireAuth(isLoggedIn);

    useEffect(() => {
        if (userId) {
            const fetchUserProfile = async () => {
                try {
                    const response = await axios.get(
                        `api/users-profiles/${userId}`,
                        {
                            headers: {
                                Authorization: `Token ${authToken}`,
                            },
                        },
                    );
                    setProfile(response.data);
                    console.log("User data fetched:", response.data);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserProfile();
        }
    }, [userId, authToken]);


    return (
        <>
            <div className="profile">
                <div className="profile-landing">
                    <div className="profile-img">
                        <ProfileImage showText={false} />
                    </div>
                    <h3>{profile.username}</h3>
                    <div className="profile-info">
                        <p>{profile.paints}<span>أعمال فنية</span></p>
                        <p>{profile.subscribers}<span>مشترك</span></p>
                    </div>
                </div>
                <div className="paints-nav">
                    <NavLink to="my-arts" activeclassname="active">أعمالي</NavLink>
                    <NavLink to="my-art-collection" activeclassname="active">مقتنياتي الفنية</NavLink>
                    <NavLink to="my-favourite" activeclassname="active">المفضلة</NavLink>
                </div>
                <div>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default Profile;