import { createContext, useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import axios from 'axios';
import { useNavigate } from "react-router-dom";


export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const { isLoggedIn, authToken } = useContext(AuthContext);
  const navigate = useNavigate();


  const addToCart = async (paintId, buyNow) => {
    try {
      const response = await axios.post(
        `api/carts`,
        { paint_id: paintId },
        { headers: { Authorization: `Token ${authToken}` } },
      );
      setCartItems(response.data);
      if (buyNow) {
        navigate('/cart')
      }
      console.log("Added new cart item:", response);
    } catch (error) {
      console.error(
        "Error adding new cart item:",
        error.response ? error.response.data : error,
      );
    }
  }

  const removeFromCart = async (cartId) => {
    try {
      const response = await axios.patch(
        `api/carts/${cartId}`,
        {}, {
        headers: { Authorization: `Token ${authToken}` }
      });
      const updatedCartItems = cartItems.map(item => {
        if (item.id === cartId) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      }).filter(item => item.quantity > 0);

      setCartItems(updatedCartItems);
      console.log("Delete cart item:", response.data);
    } catch (error) {
      console.error(
        "Error Delete cart item:",
        error.response ? error.response.data : error,
      );
    }
  };

  const clearCart = async (cartId) => {
    try {
      const response = await axios.delete(
        `api/carts/${cartId}`, {
        headers: { Authorization: `Token ${authToken}` }
      });
      setCartItems(prevItems => prevItems.filter(item => item.id !== cartId));
      console.log("Delete cart item:", response.data);
    } catch (error) {
      console.error(
        "Error Delete cart item:",
        error.response ? error.response.data : error,
      );
    }
  };

  const getCartTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.paint.price * item.quantity,
      0,
    );
  };

  const totalQuantity = () => {
    return cartItems.reduce(
      (total, item) => total + item.quantity,
      0,
    );
  };

  useEffect(() => {
    if (isLoggedIn && authToken) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }
  }, [cartItems, isLoggedIn, authToken]);

  useEffect(() => {
    const fetchCartItems = async () => {
      if (isLoggedIn && authToken) {
        try {
          const response = await axios.get('api/carts', {
            headers: {
              'Authorization': `Token ${authToken}`
            },
          });
          setCartItems(response.data);
        } catch (error) {
          console.error("Error fetching cart items:", error.response ? error.response.data : error);
        }
      }
    };
    fetchCartItems();
  }, [isLoggedIn, authToken]);


  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        totalQuantity
      }}
    >
      {children}
    </CartContext.Provider>
  );
};