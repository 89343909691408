import React, { useState, useEffect } from "react";
import PaintCard from "../PaintCard/PaintCard";
import chevron from '../../assets/images/chevron-down.svg';
import filter from '../../assets/images/filter.svg';
import arrow_left from '../../assets/images/arrow-square-left.svg';
import arrow_right from '../../assets/images/arrow-square-right.svg';
import "./ShoppingPage.css";
import axios from 'axios';

function ShoppingPage() {
    const [paints, setPaints] = useState([])

    const fetchData = async () => {
        try {
            const response = await axios.get('api/paints');
            const publishedPaints = response.data.filter(paint => paint.publish === true);
            setPaints(publishedPaints);
        } catch (error) {
            console.error('There was an error fetching the paintings:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='shopping-page'>
            <div className='container'>
                <div className="sorting">
                    <div className="text">عرض خاص 25 من 290</div>
                    <button className="button active">فرز<img src={chevron} alt="" /></button>
                    <button className="button">تصفيه<img src={filter} alt="" /></button>
                </div>
                <div className='paint-cards'>
                    {paints.map((paint, i) => {
                        return (
                            <React.Fragment key={i}>
                                <PaintCard key={i} paint={paint} />
                            </React.Fragment>
                        );
                    })}
                </div>
                <div className="navgation">
                    <img src={arrow_left} alt="" />
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <img src={arrow_right} alt="" />
                </div>
            </div>
        </div>
    )
}

export default ShoppingPage;