import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Terms from "../Terms/Terms";
import logo from '../../assets/images/logo-2.png';
import title from '../../assets/images/title-2.png';
import circle_1 from '../../assets/images/1.svg';
import circle_2 from '../../assets/images/2.svg';
import circle_3 from '../../assets/images/3.svg';
import flag from '../../assets/images/saudi-flag.png';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import ReactLoading from 'react-loading';
import "./RegisterPage.css";
import axios from 'axios';


const initialValues = {
    username: '',
    telephone: '',
    email: '',
    password: '',
    password2: '',
    terms: false
}

const validationSchema = Yup.object({
    username: Yup.string()
        .required('هذا الحقل مطلوب')
        .matches(/^[a-zA-Z0-9@._+-]+$/, 'يمكن أن يتكون اسم المستخدم من حروف وأرقام و الرموز @ . + - _ فقط بدون مسافة'),

    telephone: Yup.string()
        .matches(/^[0-9+]+$/, 'يجب أن يحتوي هذا الحقل على أرقام إنجليزيه فقط')
        .min(10, 'يجب أن تحتوي هذه الخانة على الأقل 10 أرقام')
        .required('هذا الحقل مطلوب'),

    email: Yup.string()
        .email('صيغة الايميل غير صحيحه').required('هذا الحقل مطلوب'),

    password: Yup.string()
        .required('هذا الحقل مطلوب')
        .min(8, 'كلمة المرور هذه قصيرة جدًا. يجب أن تكون 8 على الأقل')
        .matches(/[0-9]/, 'يجب ان تحتوي كلمة المرور على رقم')
        .matches(/[a-z]/, 'يجب ان تحتوي كلمة المرور على حرف صغير')
        .matches(/[A-Z]/, 'يجب ان تحتوي كلمة المرور على حرف كبير')
        .matches(/[^\w]/, "يجب ان تحتوي كلمة المرور على رمز واحد مميز على الاقل"),

    password2: Yup.string()
        .oneOf([Yup.ref('password'), null], 'كلمة المرور غير متطابقة')
        .required('هذا الحقل مطلوب'),

    terms: Yup.bool()
        .oneOf([true], 'يجب قراءة الشروط و الأحكام و الموافقة ')
        .required('هذا الحقل مطلوب')
})

function RegisterPage() {
    const [validationStatus, setValidationStatus] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false
    });
    const [showModal, setShowModal] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordIcon, setPasswordIcon] = useState(faEyeSlash);
    const [password2Type, setPassword2Type] = useState("password");
    const [password2Icon, setPassword2Icon] = useState(faEyeSlash);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const showPassword = () => {
        setPasswordType(passwordType === "password" ? "text" : "password");
        setPasswordIcon(passwordIcon === faEye ? faEyeSlash : faEye);
    };

    const showPassword2 = () => {
        setPassword2Type(password2Type === "password" ? "text" : "password");
        setPassword2Icon(password2Icon === faEye ? faEyeSlash : faEye);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const onSubmit = async (values, { setErrors }) => {
        setLoading(true);
        try {
            const response = await axios.post('auth/users/', values);
            console.log('Registration successful', response);
            navigate('/login', { state: { registrationSuccess: true } });
        } catch (error) {
            if (error.response && error.response.data) {
                console.log('err', error.response)
                setErrors(error.response.data);
            }
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordChange = (value) => {
        setValidationStatus({
            length: value.length >= 8,
            uppercase: /[A-Z]/.test(value),
            lowercase: /[a-z]/.test(value),
            number: /[0-9]/.test(value),
            special: /[^\w]/.test(value)
        });
    };

    return (
        <div className="register">
            <div className='container'>
                <div className='logo'>
                    <Link to="/">
                        <img src={logo} alt="" />
                        <img src={title} alt="" />
                    </Link>
                </div>
                <div className='main-content'>
                    <div className='form-container'>
                        <h3>إنشاء حساب</h3>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {({ handleChange }) => (
                                <Form noValidate>
                                    <div className='form-field-group'>
                                        <label htmlFor="username"><span> * </span>أسم المستخدم</label>
                                        <Field type="text" id="username" name="username" />
                                        <ErrorMessage name="username">
                                            {msg => <div className="errorMessage">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    <div className='form-field-group'>
                                        <label htmlFor="telephone"><span> * </span>رقم الهاتف</label>
                                        <img src={flag} alt='' id='flag' />
                                        <Field type="text" id="telephone" name="telephone" placeholder='+966555555555' />
                                        <ErrorMessage name="telephone">
                                            {msg => <div className="errorMessage">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    <div className='form-field-group'>
                                        <label htmlFor="email"><span> * </span>الايميل</label>
                                        <Field type="email" id="email" name="email" />
                                        <ErrorMessage name="email">
                                            {msg => <div className="errorMessage">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    <div className='form-field-group'>
                                        <label htmlFor="password"><span> * </span>كلمة المرور</label>
                                        <FontAwesomeIcon icon={passwordIcon} className='eye-icon' onClick={showPassword} />
                                        <Field type={passwordType} name="password" id="password" onChange={(e) => {
                                            handleChange(e);
                                            handlePasswordChange(e.target.value);
                                        }} />
                                        <ErrorMessage name="password">
                                            {msg => <div className="errorMessage">{msg}</div>}
                                        </ErrorMessage>
                                        <div className="password-check">
                                            <div className={validationStatus.length ? 'valid' : 'invalid'}>
                                                8 خانات على الاقل
                                            </div>
                                            <div className={validationStatus.uppercase ? 'valid' : 'invalid'}>
                                                حرف كبير (A-Z)
                                            </div>
                                            <div className={validationStatus.lowercase ? 'valid' : 'invalid'}>
                                                حرف صغير (a-z)
                                            </div>
                                            <div className={validationStatus.number ? 'valid' : 'invalid'}>
                                                رقم (0-9)
                                            </div>
                                            <div className={validationStatus.special ? 'valid' : 'invalid'}>
                                                رمز خاص (~!@#$%^&*)
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-field-group'>
                                        <label htmlFor="password2"><span> * </span>تأكيد كلمة المرور</label>
                                        <FontAwesomeIcon icon={password2Icon} className='eye-icon' onClick={showPassword2} />
                                        <Field type={password2Type} name="password2" id="password2" />
                                        <ErrorMessage name="password2">
                                            {msg => <div className="errorMessage">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    <div className='terms'>
                                        <div className='content'>
                                            <label>أوافق على <span onClick={openModal}> الشروط والأحكام</span></label>
                                            <Terms show={showModal} onClose={closeModal} />
                                            <Field type="checkbox" id="terms" name="terms" />
                                        </div>
                                        <ErrorMessage name="terms">
                                            {msg => <div className="errorMessage">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    <div className='submit'>
                                        {loading ? (
                                            <ReactLoading type="bars" color="#ffffff" height={40} width={40} />
                                        ) : (
                                            <input type="submit" value="التسجيل" />
                                        )}
                                        <div className='login-link'>
                                            هل لديك حساب بالفعل؟
                                            <Link to='/login'>سجل الدخول</Link>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className='register-image'>
                        <img src={circle_1} alt='' className='circle-one' />
                        <img src={circle_2} alt='' className='circle-two' />
                        <img src={circle_3} alt='' className='circle-three' />
                        <div className='quarter quarter-1'>
                            <div className='circle circle-1'></div>
                        </div>
                        <div className='quarter quarter-2'>
                            <div className='circle circle-2'></div>
                        </div>
                        <div className='quarter quarter-3'>
                            <div className='circle circle-3'></div>
                        </div>
                        <div className='quarter quarter-4'>
                            <div className='circle circle-4'></div>
                        </div>
                        <div className='quarter quarter-5'>
                            <div className='circle circle-5'></div>
                        </div>
                        <div className='quarter quarter-6'>
                            <div className='circle circle-6'></div>
                        </div>
                        <div className='quarter quarter-7'>
                            <div className='circle circle-7'></div>
                        </div>
                        <div className='quarter quarter-8'>
                            <div className='circle circle-8'></div>
                        </div>
                        <div className='quarter quarter-9'>
                            <div className='circle circle-9'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterPage;