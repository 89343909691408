import logo from "../../assets/images/logo.png";
import title from "../../assets/images/title.png";
import trash from "../../assets/images/trash.svg";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { CartContext } from "../contexts/CartContext";
import { FavouritePaintContext } from "../contexts/FavouritePaintContext";
import useRequireAuth from "../hooks/useRequireAuth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as solid_heart } from '@fortawesome/free-solid-svg-icons';
import "./Cart.css";

function Cart() {
    const [isFavourite, setIsFavourite] = useState({});
    const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal } = useContext(CartContext);
    const { favouritePaints, addFavouritePaint, removeFavouritePaint } = useContext(FavouritePaintContext);
    const { isLoggedIn } = useContext(AuthContext);

    useRequireAuth(isLoggedIn);

    useEffect(() => {
        const updatedFavourites = {};
        for (let item of cartItems) {
            updatedFavourites[item.paint.id] = false;
            for (let fav of favouritePaints) {
                if (item.paint.id === fav.id) {
                    updatedFavourites[item.paint.id] = true;
                    break;
                }
            }
        }
        setIsFavourite(updatedFavourites);
    }, [cartItems, favouritePaints]);

    const toggleHeart = (favId) => {
        if (isFavourite[favId]) {
            removeFavouritePaint(favId);
        } else {
            addFavouritePaint(favId);
        }
    };

    return (
        <div className="cart">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="" />
                    <img src={title} alt="" />
                </Link>
            </div>

            <hr />

            <h3>السلة</h3>
            {cartItems.length > 0 ? (
                <div className="cart-container">
                    <div className="cart-item-details">
                        {cartItems.map((item, i) => (
                            <div className="box" key={i}>
                                <div className="cart-item-info">
                                    <Link to={`/paint-details/${item.paint.id}`} >
                                        <img src={item.paint.image1} alt="" />
                                    </Link>
                                    <div>
                                        <h3>{item.paint.title}</h3>
                                        <h3>{item.paint.artist}</h3>
                                        <div id="descrption">لوحة</div>
                                    </div>
                                </div>

                                <div className="cart-item-quantity">
                                    <button
                                        onClick={() => {
                                            removeFromCart(item.id);
                                        }}
                                    >
                                        -
                                    </button>
                                    {item.quantity}
                                    <button
                                        onClick={() => {
                                            addToCart(item.paint.id);
                                        }}
                                    >
                                        +
                                    </button>
                                </div>

                                <div className="cart-item-price">
                                    <h3>{item.paint.price} ر.س</h3>
                                    <div className="icons">
                                        <img
                                            src={trash}
                                            alt=""
                                            onClick={() => clearCart(item.id)}
                                        />
                                        {!isFavourite[item.paint.id] ? (
                                            <FontAwesomeIcon icon={faHeart} className="heart-icon" onClick={() => toggleHeart(item.paint.id)} />
                                        ) : (
                                            <FontAwesomeIcon icon={solid_heart} className="heart-icon solid-heart" onClick={() => toggleHeart(item.paint.id)} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="cart-price-details">
                        {cartItems.map((item, i) => (
                            <div className="row" key={i}>
                                <h3>
                                    لوحة Whole محمد خان
                                    <span> {item.quantity}</span>
                                    <span>X</span>
                                </h3>
                                <h3>{item.quantity * item.paint.price} ر.س</h3>
                            </div>
                        ))}
                        <div className="row">
                            <h3>رسوم التوصيل</h3>
                            <div>مجانا</div>
                        </div>
                        <hr />
                        <form action="">
                            <input type="text" placeholder="كوبون الخصم" />
                            <input type="submit" value="تطبيق" />
                        </form>
                        <div className="row">
                            <h3>المجموع</h3>
                            <h3>{getCartTotal()} ر.س</h3>
                        </div>
                        <Link to={'/payment'}>
                            <button className="btn">الدفع</button>
                        </Link>
                        <h3 style={{ 'color': 'red' }}>* سيتم تفعيل الدفع قريبا</h3>
                    </div>
                </div>
            ) : (
                <div>السلة فارغة</div>
            )}
        </div>
    );
}

export default Cart;